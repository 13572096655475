import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import Rings from "../../assets/img/rings.svg";
import { API_URL } from "../../config";
import "./Footer.css";

const Footer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [footerElements, setFooterElements] = useState(null);
  const [copyright, setCopyright] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}footer?populate=section.navlink`)
      .then((response) => {
        setFooterElements(response.data.data.attributes.section);
        setCopyright(response.data.data.attributes.copyright);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="footer">
        {isLoading ? (
          <img src={Rings} alt="" />
        ) : (
          footerElements.map((footerElement) => (
            <div key={footerElement.id} className="footer-section">
              <h3>{footerElement.sectiontitle}</h3>

              {footerElement.navlink.map((navlink) =>
                navlink.url === null ? (
                  <a
                    key={navlink.id}
                    href={`https://ubeton.fr/#presentation-section`}
                  >
                    {navlink.label}
                  </a>
                ) : navlink.url === "en-construction" ? (
                  <Link to={`/${navlink.url}`} key={navlink.id}>
                    {navlink.label}
                  </Link>
                ) : navlink.url === "webapp" ? (
                  <a href="https://app.ubeton.fr">{navlink.label}</a>
                ) : (
                  <a href={`${navlink.url}`} key={navlink.id}>
                    {navlink.label}
                  </a>
                ),
              )}
            </div>
          ))
        )}
      </div>
      <div className="copyright">{copyright}</div>
    </>
  );
};

export default Footer;
