import { BACKEND_URL } from "../../config";

const Card = ({card}) => {
    return ( 
        <div className="card">
        <div className="img-container">
            <img src={`${BACKEND_URL}${card.image.data.attributes.url}`} alt=""/>
        </div>
        
        <div className="card-content">
        <h3 className="card-subtext">{card.title}</h3>
            <p className="card-text">{card.content}</p>
        </div>
    </div>);
}
 
export default Card;