import axios from "axios";
import { API_URL } from "../../config";



import tapis from "../../assets/img/tapis.png";
import pumi from "../../assets/img/pumi.png";
import toupie from "../../assets/img/toupie.png";
import { useState } from "react";

const OrdersForm = () => {
  
  const [formData, setFormData] = useState({})


  const handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData(values => ({...values, [name]: value}))
    console.log(formData)
  }

  const submitForm =  (event) => {
    event.preventDefault()
     axios.post(`${API_URL}orders`, {data: formData})
     .then(() => {
      alert("Votre demande a bien été prise en compte") 
    })
    .catch(function (error) {
      console.log(error);
    });
  };
  return (
    <div className="orders-section">
      <form className="orders-form" onSubmit={submitForm}>
        <h2 className="form-title">Commandez votre béton</h2>
        <p className="form-sub-text">
          Choisissez parmi les options ci-dessous pour commander votre béton
        </p>
        <select
          autoFocus
          name="concretetype"
          id="concretetype"
          onChange={handleChange}

        >
          <option value="" disabled selected>Type de béton :</option>
          <option value="Fondation">Fondation</option>
          <option value="Terrasse">Terrasse</option>
          <option value="Plancher">Plancher</option>
          <option value="Autoplaçant">Autoplaçant</option>
          <option value="Propreté">Propreté</option>
        </select>

        <p className="radio-btns-title">Véhicule :</p>
        <div className="radio-btns">
          <div>
            <label>
              <input
                type="radio"
                name="vehicule"
                value="Goulotte"
                onChange={handleChange}
              />
              <img className="radio-img" src={toupie} alt="" />
            </label>
          </div>

          <div>
            <label>
              <input
                type="radio"
                name="vehicule"
                value="Tapis"
                onChange={handleChange}
              />
              <img className="radio-img" src={tapis} alt="" />
            </label>
          </div>

          <div>
            <label>
              <input
                type="radio"
                name="vehicule"
                value="Pumi"
                onChange={handleChange}
              />
              <img className="radio-img" src={pumi} alt="" />
            </label>
          </div>
        </div>

        <input
          type="number"
          name="volume"
          step="0.5"
          min="0.5"
          max="14"
          placeholder="Quantité de béton désirée (en m3) *"
          onChange={handleChange}
        />

        <textarea
          name="complementaryinformation"
          cols="30"
          rows="10"
          maxLength={200}
          placeholder="Vous voulez nous en dire plus sur votre accessibilité ou votre besoin ? (200 caractères maximum)"
          onChange={handleChange}
        ></textarea>

        <input
          type="text"
          name="adress"
          placeholder="Adresse *"
          onChange={handleChange}
        />
          <input
          type="text"
          name="city"
          placeholder="Ville *"
          onChange={handleChange}
        />
        <input
          type="number"
          name="zipcode"
          maxLength={6}
          minLength={6}
          placeholder="Code postal *"
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email *"
          onChange={handleChange}
        />
        <input
          type="tel"
          name="tel"
          placeholder="N° de téléphone *"
          onChange={handleChange}
        />

        <button type="submit" className="submit-btn">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default OrdersForm;
