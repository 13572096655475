import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../config";
import Rings from "../../assets/img/rings.svg";

const ParnershipForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formTitle, setFormTitle] = useState(null);
  const [formSubtitle, setFormSubtitle] = useState(null);
  const [fields, setFields] = useState(null);
  const [submitButton, setSubmitButton] = useState(null);
  const [formData, setFormData] = useState({});

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const submitForm = async (event) => {
    event.preventDefault();
    await axios.post(`${API_URL}partners`, { data: formData }).then(() => {
      alert("Votre message a bien envoyé");
    });
  };

  useEffect(() => {
    axios.get(`${API_URL}partnership?populate=*`).then((response) => {
      setFormTitle(response.data.data.attributes.title);
      setFormSubtitle(response.data.data.attributes.Subtitle);
      setFields(response.data.data.attributes.field);
      setSubmitButton(response.data.data.attributes.submitbuttonlabel);

      setIsLoading(false);
    });
  }, []);

  return (
    <div className="partnership-content">
      <div className="partnership-form">
        <h2 className="form-title">{formTitle}</h2>
        <p className="form-sub-text">{formSubtitle}</p>
        <form onSubmit={submitForm} id="partnership-form">
          {isLoading ? (
            <img src={Rings} alt="" />
          ) : (
            fields.map((field) => (
              <input
                className="field"
                key={field.id}
                type={field.type}
                name={field.name}
                placeholder={
                  field.isrequired ? field.label + " *" : field.label
                }
                required
                onChange={handleChange}
              />
            ))
          )}
          <button className="submit-btn" id="submit-form" type="submit">
            {submitButton}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ParnershipForm;
