import Nav from "../Components/Navigation/Nav";
import ParnershipForm from "../Components/Partnership/ParnershipForm";
import './Parnership.css'
import Footer from "../Components/Footer/Footer";
import { motion } from "framer-motion"


function Partnership() {

  return (
    <motion.div transition={{ duration: 1}} initial={{opacity:0}} animate={{opacity:1}} exit={{ opacity: 0 }}>
    <Nav/>

    <ParnershipForm/>

    <Footer/>
    </motion.div> 
  );
}

export default Partnership;
