import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL, BACKEND_URL } from "../../config";
import { Link } from "react-router-dom";
import "./Hero.css";
import Icons from "./Icons";
import Rings from "../../assets/img/rings.svg";

function Hero() {
  const [links, setLinks] = useState(null);
  const [logo, setLogo] = useState(null);
  const [subtext, setSubtext] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownArrow, setDropdownArrow] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}hero?populate=*`)
      .then((response) => {
        setLinks(response.data.data.attributes.navlink);
        setLogo(response.data.data.attributes.logo.data.attributes.url);
        setSubtext(response.data.data.attributes.subtext);
        setDropdownArrow(
          response.data.data.attributes.dropdownarrow.data.attributes.url,
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="hero-container">
      <div className="hero">
        <nav className="hero-nav-links">
          {isLoading ? (
            <img src={Rings} alt="" />
          ) : (
            links.map((link) =>
              link.url !== null ? (
                <Link key={link.id} to={`/${link.url}`}>
                  {link.label}
                </Link>
              ) : (
                <a key={link.id} href="#presentation-section">
                  {link.label}
                </a>
              ),
            )
          )}
        </nav>
        <img className="logo" src={`${BACKEND_URL}${logo}`} alt="" />
        <h2 className="hero-sub-text">{subtext}</h2>
        <a href="https://app.ubeton.fr" className="cta-btn-hero">
          Commandez votre béton
        </a>
        <a href="#presentation-section">
          <img
            className="dropdown-arrow"
            src={`${BACKEND_URL}${dropdownArrow}`}
            alt=""
          />
        </a>
      </div>

      <Icons />
    </div>
  );
}

export default Hero;
