import Footer from "../Components/Footer/Footer";
import Nav from "../Components/Navigation/Nav";
import styles from "./terms-agreement.module.css";

function TermsAgreement() {
  return (
    <>
      <Nav />
      <div className={styles.terms_agreement}>
        <h1>Conditions générales d'utilisation</h1>
        <div className={styles.terms_agreement_content}>
          <div className={styles.paragraph}>
            <h2>PREAMBULE</h2>
            <p>
              UBETON SAS société au capital de 1000€, inscrite au R.C.S. de Auch
              sous le numéro 917 773 178, dont le siège social est situé au 490
              Bis route vieille, 32110 Arblade-le-haut, représenté par Olivier
              Billa dûment habilité en qualité de Président, (ci-après, l' «
              Editeur »)
            </p>
            <p>
              L'Editeur est une société ayant pour activité le développement et
              l'édition de logiciels en ligne à destination du BTP ainsi que la
              création de contenu et conseil en communication digitale. Dans le
              cadre de son activité, l'Editeur a développé un logiciel SaaS
              (Software as a Service) hébergeant notamment un algorithme qui
              valorise les retours de béton et en simplifie la traçabilité
              (ci-après, la « Solution »). Dans ce cadre, l'Editeur a notamment
              développé une offre de services autour de la Solution comme par
              exemple, la mise à disposition de la Solution, la formation, la
              création de contenu et le conseil (ci-après, les « Services »).
            </p>
            <p>
              Ci-après, le terme « Contrat », désigne la relation contractuelle
              entre l'Editeur et l'Utilisateur, étant précisé que les
              stipulations des devis, bons de commande, CGV et CGU font, sauf
              convention contraire, parties du Contrat.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 1 - Champ d'application</h2>
            <p>
              Les présentes conditions générales d'utilisation (ci-après, les «
              CGU ») ont pour objet l'encadrement juridique de l'utilisation du
              site internet www.ubeton.fr (ci-après, le « Site Internet ») en ce
              compris, l'utilisation de la Solution. En d'autres termes, elles
              définissent les droits et obligations des parties dans le cadre de
              l'accès au Site Internet et/ou à la Solution et de leur
              utilisation.
            </p>
            <p>
              Toute personne souhaitant accéder au Site Internet, est ci-après
              dénommée l'« Utilisateur ». Le Prestataire et le Client ci-après
              ensemble désignés individuellement une « Partie » et
              collectivement les « Parties ».
            </p>
            <p>
              L'Utilisateur, s'il entend recourir aux Services de l'Editeur, en
              ce compris l'utilisation de la Solution, devra également se
              conformer et accepter les conditions générales de vente de
              l'Editeur.
            </p>
            <p>
              Si l'Utilisateur utilise de quelque manière que ce soit la
              Solution et/ou le Site Internet, il s'engage à respecter les
              termes des présentes CGU lesquelles font partie intégrante de la
              relation contractuelle existante entre l'Editeur et l'Utilisateur.
            </p>
            <p>
              En conséquence, en accédant au Site Internet et en utilisant la
              Solution, l'Utilisateur :
            </p>
            <ul>
              <li>
                reconnait expressément et sans réserve avoir pris connaissance
                de l'ensemble des conditions qui suivent et s'engage à les
                respecter, en son nom et le cas échéant pour le compte de
                l'ensemble de ses préposés, salariés ou agents ;
              </li>
              <li>
                reconnait au préalable avoir pris connaissance des
                potentialités, de la finalité, des fonctionnalités, du caractère
                standard et du mode opératoire de la Solution détaillé au devis
                et/ou sur le Site Internet ;
              </li>
              <li>
                reconnait avoir reçu l'ensemble des informations et conseils
                permettant de prendre la mesure de la proposition commerciale et
                s'est assuré de la conformité de la Solution à ses besoins ;
              </li>
            </ul>
            <p>
              Le fait que l'Editeur ne se prévale pas à un moment donné de l'une
              quelconque des présentes CGU ne peut être interprété comme valant
              renonciation à s'en prévaloir ultérieurement. Les présentes CGU
              sont conclues pour une durée indéterminée et produisent leurs
              effets à l'égard de l'Utilisateur à compter du début de
              l'utilisation du Site Internet et/ou de la Solution. L'Editeur est
              libre de modifier, compléter ou actualiser les CGU, sans préavis,
              notamment afin de prendre en compte une évolution législative,
              réglementaire, jurisprudentielle et/ou technique. Par ailleurs,
              l'Editeur est libre de faire évoluer la Solution sans information
              préalable du Client dans la mesure où cela ne génère aucune
              dégradation des Services, ni de pertes de données.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 2 - Accès au Site Internet et à la Solution</h2>
            <p>
              Tout Utilisateur ayant accès à internet peut accéder gratuitement
              et depuis n'importe quel support en n'importe quel lieu, au Site
              Internet. Les frais supportés par l'Utilisateur pour y accéder
              (notamment connexion internet, matériel informatique, etc.) sont à
              sa charge exclusive et ne peuvent en aucun cas être imputés à
              l'Editeur.
            </p>
            <p>
              Pour utiliser la Solution, il convient d'être équipé d'un
              ordinateur et/ou appareil mobile et/ou tablette, que l'Utilisateur
              possède ou contrôle, sans que ce dernier ne puisse exiger que
              l'Editeur mette à sa disposition un quelconque matériel
              informatique.
            </p>
            <p>
              L'Utilisateur reconnait expressément qu'il ne peut imputer une
              quelconque responsabilité à l'Editeur si son matériel informatique
              et/ou sa connexion internet ne sont pas en adéquation avec
              l'utilisation de la Solution et ne permettent ainsi pas de
              l'utiliser.
            </p>
            <p>
              Le Site Internet et la Solution peuvent être interrompus ou
              suspendus par l'Éditeur, notamment à l'occasion d'une maintenance
              ou mise à jour, sans une quelconque obligation de préavis ou de
              justification auprès de l'Utilisateur.
            </p>
            <p>
              En tout état de cause, il appartient à l'Utilisateur de prendre
              toutes les précautions appropriées pour faire face à un éventuel
              dysfonctionnement du Site Internet ou de la Solution dans le cadre
              de leur utilisation, en particulier par la mise en place d'une
              sauvegarde préalable des données traitées et de contrôles
              réguliers des résultats.
            </p>
            <p>
              Le Site Internet comprend un espace membre payant réservé aux
              Utilisateurs inscrits, ces derniers pouvant notamment y accéder en
              utilisant leurs identifiants de connexion préalablement choisis au
              moment de la création de leur compte personnel. A ce titre, ils
              conviennent expressément de garder leur identifiant et mot de
              passe (et autres détails associés à leur compte) strictement
              confidentiel. L'Utilisateur étant ainsi seul responsable de
              l'utilisation, de la conservation et de la confidentialité de son
              identifiant et mot de passe. La licence d'utilisation de la
              Solution accordée par l'Editeur est une licence d'utilisation
              concédée à l'Utilisateur à titre personnel, non-exclusive, non
              transférable, et non-cessible.
            </p>
            <p>
              En aucun cas, l'Utilisateur n'a le droit de transférer ou de
              sous-licencier l'utilisation de la Solution, ni de copier, de
              faire de l'ingénierie inverse, de tenter de dériver le code
              source, de modifier ou d'utiliser la Solution ou toute partie de
              celle-ci en dehors de l'objet pour lequel elle est destinée.
              L'Utilisateur n'a pas non plus le droit de télécharger de virus ou
              assimilables et/ou de logiciel espion.
            </p>
            <p>
              En cas d'alerte de sécurité pouvant laisser penser à un piratage,
              un accès frauduleux ou toute atteinte au système d'information de
              l'Editeur, celui-ci pourra suspendre tout ou partie des accès, de
              manière discrétionnaire, immédiate et sans préavis. Cette
              interruption n'ouvre droit à aucune indemnité au bénéfice de
              l'Utilisateur.
            </p>
            <p>
              L'Editeur devra toutefois communiquer à l'Utilisateur les causes
              exactes et le détail de cette alerte de sécurité au plus tôt et en
              tout état de cause dans les vingt-quatre (24) heures ayant suivi
              celles-ci. En cas d'alerte de sécurité concernant l'accès à tout
              ou partie des informations de l'Utilisateur traitées par le Site
              Internet et/ou la Solution, l'Editeur s'engage à en avertir
              l'Utilisateur de façon circonstanciée dans les vingt-quatre (24)
              heures ayant suivi celle-ci afin que ce dernier puisse respecter
              ses obligations contractuelles et /ou légales.
            </p>
            <p>
              L'Editeur est libre d'empêcher ou suspendre l'accès au Site
              Internet et/ou à la Solution, sans préavis s'il considère
              raisonnablement que l'Utilisateur n'a pas respecté une partie des
              présentes CGU ou toute condition ou politique à laquelle elles se
              réfèrent ou toute loi applicable ou si l'utilisation qui est faite
              porte préjudice ou est susceptible de porter préjudice aux
              intérêts de l'Editeur ou au fonctionnement normal du Site Internet
              et/ou de la Solution.
            </p>
            <p>
              En outre, l'Utilisateur s'engage à faire une utilisation du Site
              Internet et de la Solution qui ne contrevienne à aucune loi ou
              aucun règlement, à ne pas faire traiter par le Site Internet ou la
              Solution des données illicites et garantit ainsi l'Editeur contre
              tout recours de ces faits.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 3 - Responsabilité</h2>
            <p>
              L'Editeur est soumis, notamment pour l'accès, l'utilisation et la
              disponibilité du Site Internet ou de la Solution, à une obligation
              de moyens, étant précisé qu'il en est de même dans le cadre de la
              réalisation des Services. En cas de préjudice prouvé imputable à
              l'Editeur, celui-ci n'est tenu que des dommages et intérêts qui
              pouvaient être prévus lors de la conclusion du Contrat et qui ne
              comprennent que ce qui est une suite immédiate et directe de
              l'inexécution au sens des articles 1231-3 et 1231-4 du Code civil.
            </p>
            <p>
              En tout état de cause, la responsabilité de l'Éditeur ne peut être
              engagée en cas de défaillance, panne, difficulté ou interruption
              de fonctionnement, empêchant l'accès au Site Internet ou à une de
              ses fonctionnalités, notamment la Solution.
            </p>
            <p>
              Le matériel de connexion au Site Internet utilisé est sous
              l'entière responsabilité de l'Utilisateur qui doit prendre toutes
              les mesures appropriées pour protéger son matériel informatique et
              les données notamment d'attaques virales par Internet.
              L'Utilisateur est par ailleurs le seul responsable des sites et
              données qu'il consulte.
            </p>
            <p>
              L'Éditeur n'est pas responsable des dommages causés à
              l'Utilisateur ou à l'équipement de l'Utilisateur du fait de sa
              connexion ou de son utilisation du Site Internet et/ou de la
              Solution et l'Utilisateur renonce à toute action contre l'Éditeur
              de ce fait. L'Editeur ne saurait par ailleurs être responsable de
              toute perte de données que ce soit à la suite de virus
              informatiques, de logiciels espions ou de logiciels malveillants.
            </p>
            <p>
              S'agissant particulièrement de l'utilisation de la Solution : les
              calculs, résultats, simulations, analyses, tests, estimations et
              toutes autres données émanant de la Solution, sont fournies à
              titre informatif et indicatif, et ne se substituent nullement au
              jugement professionnel de l'Utilisateur, étant précisé que
              l'Utilisateur a, seul, une vue d'ensemble sur ses besoin en
              bétonnage et sur les méthodes prévues pour sa réalisation.
              L'Utilisateur est responsable de l'utilisation de la Solution et
              de l'interprétation et de l'utilisation des résultats obtenus.
              L'Utilisateur s'engage notamment à utiliser la Solution
              conformément à son usage et sa destination. En conséquence,
              l'Utilisateur reconnait expressément que l'Editeur, ne saurait
              aucunement être tenu responsable des résultats obtenus
              consécutivement à l'utilisation de la Solution, y compris toute
              donnée obtenue, de quelque nature que ce soit. De plus,
              l'Utilisateur reconnait que l'utilisation de la Solution et les
              résultats/données obtenus peuvent ne pas fournir à l'Utilisateur,
              les résultats souhaités dans le cadre de ses conceptions,
              analyses, simulations, estimations, tests et autres contraintes,
              l'Editeur ne garantissant aucunement l'atteinte d'un résultat
              quelconque. A ce titre, toutes déclarations à propos de la
              Solution ne sont faites qu'à titre indicatif et ne sauraient
              constituer une garantie, déclaration, condition ou autre
              engagement. Par ailleurs, L'Editeur ne garantit pas et ne s'engage
              pas sur la disponibilité continue de la Solution, l'absence
              d'erreur des données et/ou leur fiabilité, exactitude et
              exhaustivité.
            </p>
            <p>
              Pour tous les dommages, y compris en cas de responsabilité
              personnelle de l'Editeur, la réparation due par l'Editeur est
              strictement limitée au prix du Service (droits, taxes et frais
              divers exclus) à l'origine du dommage, objet du Contrat.
            </p>
            <p>
              L'Editeur ne pourra en aucun cas être tenu responsable (i) des
              dommages qui ne procèderaient pas d'une faute qu'il aurait commise
              et prouvée par l'Utilisateur, (ii) des dommages indirects, (iii)
              des pertes économiques (y compris, de façon non limitative :
              pertes de revenus, de données, de bénéfices, de contrats, ou
              encore pertes commerciales ou d'économies escomptées) et (iv) de
              tout préjudice d'image (effet négatif sur l'image ou atteinte à la
              réputation).
            </p>
            <p>
              Aucune responsabilité n'est assumée par l'Editeur, de manière
              expresse ou implicite, à raison de l'usage fait par l'Utilisateur
              du Site Internet et/ou de la Solution fournis par l'Editeur auprès
              de tiers, quels qu'ils soient. L'Editeur ne saurait assumer
              quelque réclamation que ce soit à raison de tout dommage causé à
              un tiers quelconque du fait d'une telle utilisation. Toute
              réparation d'un dommage par ricochet est en conséquence exclue de
              l'étendue de la responsabilité supportée par L'Editeur.
            </p>
            <p>
              En toute état de cause, l'Editeur et l'Utilisateur ne pourront
              être tenus pour responsables si la non-exécution ou le retard dans
              l'exécution de l'une quelconque de leurs obligations, telles que
              décrites dans les présentes découle d'un cas de force majeure, au
              sens de l'article 1218 du Code civil et de l'interprétation qui en
              est faite par les juridictions françaises.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 4 - Propriété intellectuelle</h2>
            <p>
              Les marques, logos, logiciels, algorithmes, noms de domaines,
              textes, dessins et modèles, photos, vidéos, rapports ou plus
              généralement toute information objet de droits de propriété
              intellectuelle et/ou industrielle sont et restent la propriété
              exclusive de l'Editeur. Toute reproduction totale ou partielle,
              modification ou utilisation de ces biens pour quelque motif que ce
              soit est strictement interdite. En conséquence, à l'exception de
              la licence d'utilisation de la Solution, l'Utilisateur reconnait
              expressément que l'Editeur ne transfère ni ne concède aucun droit
              de propriété intellectuelle et/ou industrielle, quel qu'il soit, à
              l'Utilisateur. L'Editeur demeure seul titulaire de l'ensemble de
              ses droits de propriété industrielle et/ou intellectuelle.
            </p>
            <p>
              L'Utilisateur s'interdit de citer et/ou d'utiliser le nom, les
              marques, les logos, les noms de domaine et/ou les autres signes
              distinctifs de l'Editeur ou de mentionner l'existence ainsi que le
              contenu du Contrat à titre de référence commerciale ou autre sans
              l'autorisation préalable et écrite de l'Editeur. L'Editeur pouvant
              demander communication préalable de la communication envisagée.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 5 - Liens hypertextes</h2>
            <p>
              Toute information accessible via un lien vers d'autres sites n'est
              pas sous le contrôle de l'Éditeur qui décline toute responsabilité
              quant à leur contenu.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 6 - Confidentialité</h2>
            <p>
              L'Utilisateur s'engage à garder confidentielles toutes
              informations quels qu'en soient la nature, la forme et le support,
              informations, documents, données et renseignements notamment
              organisationnelle, technique, opérationnelle, stratégique,
              financière, commerciale, juridique ou autre, communiquées de
              quelque manière que ce soit (écrite, verbale, visuelle,
              électronique ou autre) tels que des échantillons, listes de
              matériels, supports informatiques, programmes informatiques, codes
              sources de logiciels, algorithme, cahier des charges, bases de
              données, droits d'auteur, savoir-faire, connaissances, concepts,
              secret des affaires, échantillons, brevets déposés ou enregistrés,
              etc., notamment transmis par tous moyens et sur tout support, sans
              que cette liste ne soit limitative et exhaustive.
            </p>
            <p>
              Le présent engagement de confidentialité prend effet à compter de
              l'entrée en relation des Parties, et prendra fin, à l'expiration
              d'un délai de trois (3) ans à compter de la fin du Contrat.
            </p>
            <p>
              Sauf convention contraire expresse, le Client accepte d'être cité
              par le Prestataire comme client de ses Services. A cet effet, le
              Client autorise le Prestataire à mentionner son nom ainsi qu'une
              description objective de la nature des prestations réalisées dans
              ses listes de références et propositions à l'attention de ses
              prospects et de sa clientèle, entretiens avec des tiers, rapports
              d'activité, ainsi qu'en cas de dispositions légales,
              réglementaires ou comptables l'exigeant.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 7 - Données à caractère personnel - RGPD</h2>
            <p>
              Dans le cadre de la réalisation des Services, et notamment de
              l'Utilisation de la Solution, et afin d'assurer la bonne exécution
              de ses obligations à ce titre, l'Editeur est susceptible de
              traiter des données personnelles de l'Utilisateur, de ses
              salariés, collaborateurs, agents, préposés, mandataires, notamment
              mandataires sociaux, ou de toute personne physique ou morale
              chargée de le représenter dans ses communications et échanges de
              quelque nature que ce soit avec l'Editeur (ci-après les « Données
              Personnelles »).
            </p>
            <p>
              Les Données Personnelles susceptibles d'être collectées sur le
              Site Internet sont les suivantes :
            </p>
            <ul>
              <li>
                ouverture de compte : lors de la création du compte de
                l'Utilisateur : nom ; prénom ; poste ; adresse électronique ; n°
                de téléphone ; adresse postale ; mentions légales de sa société
                ;
              </li>
              <li>
                connexion : lors de la connexion de l'Utilisateur au Site
                Internet, celui-ci enregistre, notamment, ses nom, prénom,
                données de connexion, d'utilisation, de localisation;
              </li>
              <li>
                profil : l'utilisation des prestations prévues sur le Site
                Internet permet de renseigner un profil, pouvant comprendre une
                adresse et un numéro de téléphone ;
              </li>
              <li>
                paiement : dans le cadre du paiement des produits et prestations
                proposés sur le Site Internet, l'Editeur a recours à un
                prestataire externe qui fait son affaire personnelle de la
                collecte et du traitement des Données Personnelles, étant
                précisé que l'Editeur ne collecte aucune Donnée Personnelles de
                nature financière tel que les coordonnées du compte bancaire ou
                de la carte de crédit de l'Utilisateur. ;
              </li>
              <li>
                communication : lorsque le Site Internet est utilisé pour
                communiquer avec d'autres membres, les données concernant les
                communications de l'Utilisateur font l'objet d'une conservation
                temporaire.
              </li>
            </ul>
            <p>
              Le Site Internet met en oeuvre des mesures organisationnelles,
              techniques, logicielles et physiques en matière de sécurité du
              numérique pour protéger les Données Personnelles contre les
              altérations, destructions et accès non autorisés. Toutefois, il
              est à signaler qu'internet n'est pas un environnement complètement
              sécurisé et le Site Internet ne peut pas garantir la sécurité de
              la transmission ou du stockage des informations sur internet.
            </p>
            <p>
              Le responsable du traitement est l'Editeur, dont la désignation
              figure en préambule des CGU. Le délégué à la protection des
              données (« DPO ») peut être contacté à l'adresse suivante :
              contact@ubeton.fr Les Données Personnelles de l'Utilisateur sont
              traitées en vue de la bonne exécution des prestations et services
              et en application d'obligations légales ou réglementaires
              incombant à l'Editeur ès-qualités ou en vue de poursuivre ses
              intérêts légitimes.
            </p>
            <p>
              Les Données Personnelles collectées auprès des Utilisateurs ont
              pour objectif la mise à disposition des services du site web, leur
              amélioration et le maintien d'un environnement sécurisé. Plus
              précisément, les utilisations sont notamment les suivantes :
            </p>
            <ul>
              <li>accès et utilisation du Site Internet par l'Utilisateur ;</li>
              <li>
                gestion du fonctionnement et optimisation du Site Internet;
              </li>
              <li>
                vérification, identification et authentification des données
                transmises par l'Utilisateur ;
              </li>
              <li>
                proposition à l'Utilisateur de la possibilité de communiquer
                avec d'autres Utilisateurs du Site Internet ;
              </li>
              <li>mise en œuvre d'une assistance utilisateurs ;</li>
              <li>
                personnalisation des services en affichant des publicités en
                fonction de l'historique de navigation de l'Utilisateur, selon
                ses préférences ;
              </li>
              <li>
                prévention et détection des fraudes, malwares (malicious
                softwares ou logiciels malveillants) et gestion des incidents de
                sécurité ;
              </li>
              <li>gestion des éventuels litiges avec les Utilisateurs ;</li>
              <li>
                envoi d'informations commerciales et publicitaires, en fonction
                des préférences de l'Utilisateur
              </li>
            </ul>
            <p>
              Les Données Personnelles traitées sont réservées à l'usage de
              l'Editeur. Toutefois, les Données Personnelles sont susceptibles
              d'être partagées avec des sociétés tierces, dans les cas suivants
              :
            </p>
            <ul>
              <li>
                lorsque l'Utilisateur utilise des services de paiement, le Site
                Internet est en relation avec un prestataire sécurisé tiers ;
              </li>
              <li>
                lorsque l'Utilisateur publie, dans les zones de commentaires
                libres du Site Internet, des informations accessibles au public
                ;
              </li>
              <li>
                lorsque l'Utilisateur autorise le Site Internet d'un tiers à
                accéder à ses données ;
              </li>
              <li>
                lorsque le Site Internet recourt aux services de prestataires
                pour fournir l'assistance utilisateurs, la publicité et les
                services de paiement. Ces prestataires disposent d'un accès
                limité aux données de l'Utilisateur, dans le cadre de
                l'exécution de ces prestations, et ont une obligation
                contractuelle de les utiliser en conformité avec les
                dispositions de la réglementation applicable en matière
                protection des données à caractère personnel ;
              </li>
              <li>
                lorsque le Site Internet recourt aux services de prestataires
                pour fournir une offre en béton. Ces prestataires disposent d'un
                accès limité aux données de l'Utilisateur, dans le cadre de
                l'exécution de ces prestations, et ont une obligation
                contractuelle de les utiliser en conformité avec les
                dispositions de la réglementation applicable en matière
                protection des données à caractère personnel ;
              </li>
              <li>
                si la loi l'exige, le Site Internet peut effectuer la
                transmission de données pour donner suite aux réclamations
                présentées contre le site web et se conformer aux procédures
                administratives et judiciaires;
              </li>
              <li>
                si le Site Internet est impliqué dans une opération de fusion,
                acquisition, cession d'actifs ou procédure de redressement
                judiciaire, il pourra être amenée à céder ou partager tout ou
                partie de ses actifs, y compris les données à caractère
                personnel. Dans ce cas, les Utilisateurs seraient informés,
                avant que les données à caractère personnel ne soient
                transférées à une tierce partie.
              </li>
              <li>
                L'Editeur veillera au respect des dispositions de la loi n°78-17
                du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
                libertés, modifiée par la loi n°2004-801 du 6 août 2004 relative
                à la protection des personnes physiques à l'égard des
                traitements de Données Personnelles, ainsi que le règlement
                européen n°2016-679 à compter du 25 mai 2018 (ci-après ensemble
                la « Règlementation »).
              </li>
              <li>
                Conformément à la Réglementation, l'Editeur s'engage à traiter
                les Données Personnelles de façon à garantir un niveau de
                sécurité approprié, y compris la protection contre le traitement
                non autorisé ou illicite et contre la perte, la destruction ou
                les dégâts d'origine accidentelle, à l'aide de mesures
                techniques ou organisationnelles appropriées.
              </li>
              <li>
                Les Données Personnelles ne sont pas conservées sous une forme
                permettant leur identification au-delà de la durée nécessaire au
                vu des finalités pour lesquelles elles sont traitées et des
                prescriptions légales et réglementaires
              </li>
              <li>
                L'Utilisateur dispose d'un droit d'accès, de portabilité et de
                rectification, d'opposition pour motif légitime, de limitation,
                et de suppression, effacement de complément d'informations sur
                le traitement des Données Personnelles les concernant en
                adressant un mail au DPO.
              </li>
            </ul>
            <p>
              Lorsque le traitement d'une Donnée Personnelle est fondé sur le
              consentement de l'Utilisateur, ce dernier dispose du droit de
              retirer son consentement à tout moment.
            </p>
            <p>
              L'Utilisateur est informé qu'il a la possibilité d'introduire une
              réclamation auprès de la Commission nationale de l'informatique et
              des libertés (CNIL).
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 8 - Cookies</h2>
            <p>
              Le Site Internet peut collecter automatiquement des informations
              standards (notamment données de connexion, d'utilisation, de
              localisation). Toutes les informations collectées indirectement ne
              seront utilisées que pour suivre le volume, le type et la
              configuration du trafic utilisant ce site, pour en développer la
              conception et l'agencement et à d'autres fins administratives et
              de planification et plus généralement pour améliorer les Services
              proposé par l'Editeur.
            </p>
            <p>
              L'Utilisateur a la possibilité de désactiver les cookies à partir
              des paramètres de son navigateur. En cas de difficultés et
              interrogations relatives aux cookies, l'Utilisateur est invité à
              contacter l'Editeur à l'adresse suivante : contact@ubeton.fr
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 9 - Nullité d'une clause</h2>
            <p>
              Les dispositions des CGU sont distinctes et autonomes les unes par
              rapport aux autres. À tout moment, si une ou plusieurs de ces
              dispositions devenaient invalide(s), illégale(s) ou
              inapplicable(s) au regard d'une loi ou d'un décret applicable ou
              d'une décision judiciaire définitive, la validité, la légalité et
              l'applicabilité des dispositions restantes ne sera pas affectée,
              et les Parties conviennent de leurs substituer des dispositions
              similaires qui ne soient pas elles-mêmes invalides, illégales ou
              inapplicables
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>ARTICLE 10 - Loi applicable et litiges</h2>
            <p>
              Les CGU sont rédigées en langue française et soumises à la loi
              française.{" "}
            </p>
            <p>
              Tout différend, non résolu à l'amiable entre les Parties dans le
              délai d'un (1) mois (sauf délai plus long convenu entre les
              Parties), et relatif à la validité, l'exécution ou
              l'interprétation des présentes CGU et/ou l'Utilisation du Site
              Internet et/ou de la Solution sera soumis à la compétence du
              Tribunal de Commerce de Auch, y compris en cas de pluralité de
              défendeurs.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsAgreement;
