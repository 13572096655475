import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { BACKEND_URL,API_URL } from '../../config';
import Rings from '../../assets/img/rings.svg'

const Icons = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [icons, setIcons] = useState(null)

    useEffect(() => {
        axios
          .get(`${API_URL}hero?populate=icon.image`)
          .then((response) => {
            setIcons(response.data.data.attributes.icon)
             setIsLoading(false)
             
          })
          .catch((error) => {
            console.log(error);
          });
        },[]);

    return ( 
        <div className="icon-layout">
    {isLoading ? <img src={Rings} alt="" /> : icons.map(icon => 
            <div data-hover={`${icon.title} - ${icon.text} `} className="icon-container" key={icon.id}>
            <img  src={`${BACKEND_URL}${icon.image.data.attributes.url}`} alt="" />
            </div>)}
            
        </div> 
    
        );
}
 
export default Icons;