import Nav from "../Components/Navigation/Nav";
import Footer from "../Components/Footer/Footer";
import OrdersForm from "../Components/Orders/OrdersForm";
import './Orders.css'

const Building = () => {
    return ( 
    
    <>
    <Nav/>
    <OrdersForm/>
    <Footer/> 
    </>);
}
 
export default Building;