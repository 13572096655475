import axios from 'axios'
import {useEffect, useState} from 'react'
import {API_URL, BACKEND_URL} from '../../config'
import Rings from '../../assets/img/rings.svg'

const Awards = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [awards, setAwards] = useState(null)
    const [awardsSubtext, setAwardsSubtext] = useState(null)
  
    
    useEffect(() => {
      axios
        .get(`${API_URL}award?populate=*`)
        .then((response) => {
           setAwards(response.data.data.attributes.images.data)
           setAwardsSubtext(response.data.data.attributes.sectiontitle)
           setIsLoading(false);
           
        })
        .catch((error) => {
          console.log(error);
        });
    },[]);
    
    return (
      <>        
      <h2 className="section-title section-awards">{awardsSubtext}</h2> 
    <div className="brands-container">
        { isLoading ? <img src={Rings} alt="" /> : awards.map( image => (
                <div key={image.id} className="img-container">
                <img src={`${BACKEND_URL}${image.attributes.url}`} alt=""/>
            </div>

        ))}
</div> </>);
}
 
export default Awards;