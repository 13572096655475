import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL, BACKEND_URL } from "../../config";
import Rings from "../../assets/img/rings.svg";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);
  const [sectionTitle, setSectionTitle] = useState(null);
  const [presentations, setPresentations] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}about?populate=section.image`)
      .then((response) => {
        setSectionTitle(response.data.data.attributes.sectiontitle);
        setPresentations(response.data.data.attributes.section);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <a className="anchor" 
         href="/" 
         id="presentation-section">
        ANCHOR
      </a>
      <h2 className="section-title">{sectionTitle}</h2>
      
      <section className="presentation-section">
        {isLoading ? (
          <img src={Rings} alt="" />
        ) : (
          presentations.map((presentation) =>
            presentation.imageposition === "droite" ? (
              <div className="about-container" key={presentation.id}>
                <div className="about-text">
                  <p>{presentation.text}</p>
                </div>
                <div className="about-img">
                  <img
                    alt=""
                    src={`${BACKEND_URL}${presentation.image.data.attributes.formats.medium.url}`}
                  />
                </div>
              </div>
            ) : (
              <div className="about-container" key={presentation.id}>
                <div className="about-img">
                  <img
                    alt=""
                    src={`${BACKEND_URL}${presentation.image.data.attributes.formats.medium.url}`}
                  />
                </div>
                <div className="about-text">
                  <p style={{ textAlign: "right" }}>
                    {presentation.text}
                  </p>
                </div>
              </div>
            )
          )
        )}
      </section>
    </>
  );
}

export default AboutUs;
