import axios from 'axios'
import {useEffect, useState} from 'react'
import {API_URL} from '../../config'
import { Link } from 'react-router-dom'
import Rings from '../../assets/img/rings.svg'



const CallToAction = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(null)
    const [subtitle, setSubtitle] = useState(null)
    const [text, setText] = useState(null)
    const [partnershipButton, setPartnershipButton] = useState(null)
    const [newsletterButton, setNewsletterButton] = useState(null)
    const [ctaField, setCtaField] = useState(null)
    const [isNewsletterLoading, setIsNewsletterLoading] = useState(true)
    
    useEffect(() => {
      axios
        .get(`${API_URL}cta?populate=*`)
        .then((response) => {
           setTitle(response.data.data.attributes.title)
           setSubtitle(response.data.data.attributes.subtitle)
           setText(response.data.data.attributes.text)
           setPartnershipButton(response.data.data.attributes.button)
           setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

        axios
        .get(`${API_URL}newsletter?populate=*`)
        .then((response) => {
            setCtaField(response.data.data.attributes.field);
            setNewsletterButton(response.data.data.attributes.button)
            setIsNewsletterLoading(false);
           
        })
        .catch((error) => {
          console.log(error);
        });
    },[]);

    return ( 

        isLoading || isNewsletterLoading ? <img src={Rings} alt="" /> : (
            <div className="call-to-action">
                    <a href="https://app.ubeton.fr" className='cta-btn'>Commandez votre béton</a> 
            <h2 className="cta-title">{title}</h2>
            <div className="cta-content">
                <h3 className="cta-sub-text">{subtitle}</h3>
                <p className="cta-text">{text}</p>
            </div>
            <a className="cta-btn-partnership" href="https://app.ubeton.fr">{partnershipButton.label}</a>
            <div className="newsletter">
                <form action={newsletterButton.url} method="post">
                    {ctaField.isrequired === true ?
                     <input className="newsletter-email" type={ctaField.type} name={ctaField.name} id="tlemail" placeholder="Entrez votre email" required />
                     :
                     <input className="newsletter-email" type={ctaField.type} name={ctaField.name} id="tlemail" placeholder="Entrez votre email" />
                        
                    }
                    
                    <input type="hidden" value="1" name="embed" />
                    <input type="submit" className="newsletter-submit" value={newsletterButton.label}/>
                </form>
            </div>
        </div>
        )
       
 );
}
 
export default CallToAction;