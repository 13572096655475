import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

import LandingPage from "./Pages/LandingPage";
import Partnership from "./Pages/Partenership";
import Building from "./Pages/Building";
import Blog from "./Pages/Blog";
import Orders from "./Pages/Orders";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Legal from "./Pages/Legal";
import { clarity } from "react-microsoft-clarity";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import TermsAgreement from "./Pages/TermsAgreement";

function App() {
  const { trackPageView } = useMatomo();

  return (
    <BrowserRouter>
      <AnimatePresence>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="devenez-partenaire" element={<Partnership />} />
          <Route exact path="blog" element={<Blog />} />
          <Route exact path="en-construction" element={<Building />} />
          <Route exact path="commandez-votre-beton" element={<Orders />} />
          <Route exact path="mentions-legales" element={<Legal />} />
          <Route
            exact
            path="conditions-generales-d-utilsiation"
            element={<TermsAgreement />}
          />
        </Routes>
        <CookieConsent
          onAccept={() => {
            trackPageView();
            clarity.init("4byxu6c9c3");
          }}
          enableDeclineButton
          location="bottom"
          buttonText="Accepter"
          declineButtonText="Refuser"
          cookieName="matomo&clarity"
          style={{
            background: "#2eb574",
            fontSize: "22px",
            fontWeight: 700,
          }}
          declineButtonStyle={{ color: "#FFFFFF", fontSize: "18px" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "18px",
            backgroundColor: "#FFFFFF",
          }}
          expires={150}
        >
          Ubéton utilise des cookies afin d'améliorer l'expérience utilisateur.{" "}
        </CookieConsent>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default App;
