import { useEffect, useState } from "react";

import BlogArticle from "./BlogArticle";

import axios from "axios";

import { API_URL } from "../../config";
import Rings from "../../assets/img/rings.svg";

const BlogArticles = () => {
  const [articles, setArticles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}blogs?populate=*`)
      .then((response) => {
        setArticles(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  return (
    <>
      {isLoading ? (
        <img src={Rings} alt='' />
      ) : (
        articles.map((article) => 
        <BlogArticle article={article} key={article.id} />)
      )}
    </>
  );
};

export default BlogArticles;
