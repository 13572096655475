import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL, BACKEND_URL } from "../../config";
import { Link } from "react-router-dom";
import "./Nav.css";
import Rings from "../../assets/img/rings.svg";

function Nav() {
  const [hamburgerShow, setHamburgerShow] = useState("");
  const [hamburgerMenuIsDisplayed, sethamburgerMenuIsDisplayed] =
    useState(false);
  const [links, setLinks] = useState(null);
  const [logo, setLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}navbar?populate=*`)
      .then((response) => {
        setLinks(response.data.data.attributes.navlink);
        setLogo(response.data.data.attributes.logo.data.attributes.url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const displayNav = () => {
    setHamburgerShow("hamburger-show");
    sethamburgerMenuIsDisplayed(true);
  };

  const hideNav = () => {
    setHamburgerShow("none");
    sethamburgerMenuIsDisplayed(false);
  };

  return (
    <div className="navbar">
      <div className="img-container">
        <a href={`https://ubeton.fr/#presentation-section`}>
          <img src={`${BACKEND_URL}${logo}`} alt="" />
        </a>
      </div>
      <nav className="nav-links">
        {isLoading ? (
          <img src={Rings} alt="" />
        ) : (
          links.map((link) =>
            link.url !== null ? (
              <Link key={link.id} to={`/${link.url}`}>
                {link.label}
              </Link>
            ) : (
              <a key={link.id} href={`https://ubeton.fr/#presentation-section`}>
                {link.label}
              </a>
            ),
          )
        )}
        <a href="https://app.ubeton.fr">Commandez du béton</a>
      </nav>

      <p
        onClick={
          hamburgerMenuIsDisplayed ? () => hideNav() : () => displayNav()
        }
        className="hamburger-button"
      >
        ☰
      </p>
      <nav className={`hamburger-nav ${hamburgerShow}`}>
        {isLoading ? (
          <img src={Rings} alt="" />
        ) : (
          links.map((link) =>
            link.url !== null ? (
              <Link
                onClick={() => () => hideNav()}
                key={link.id}
                to={`/${link.url}`}
              >
                {link.label}
              </Link>
            ) : (
              <a
                onClick={() => () => hideNav()}
                key={link.id}
                href={`https://ubeton.fr/#presentation-section`}
              >
                {link.label}
              </a>
            ),
          )
        )}
      </nav>
    </div>
  );
}

export default Nav;
