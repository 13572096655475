import axios from 'axios'
import {useEffect, useState} from 'react'
import {API_URL} from '../../config'
import Card from './Card'
import Rings from '../../assets/img/rings.svg'

const Cards = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState(null)
  const [dealsSubtext, setDealsSubtext] = useState(null)

  
  useEffect(() => {
    axios
      .get(`${API_URL}deal?populate=card.image`)
      .then((response) => {
         setCards(response.data.data.attributes.card)
         setDealsSubtext(response.data.data.attributes.sectiontitle)
         setIsLoading(false);
         
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

    return (
    <>
       
        <div className="card-section">
        <h2 className="section-title section-deals">{dealsSubtext}</h2>
        <div className="card-container">
        {isLoading ? <img src={Rings} alt="" /> : cards.map(card =>(
            
            <Card key={card.id} card={card}/>
            

            ))
        }</div></div></>
        
    );
    }
export default Cards;

