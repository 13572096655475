
import React from 'react'
import AboutUs from "../Components/HomePage/AboutUs";
import Hero from "../Components/HomePage/Hero";
import Cards from "../Components/HomePage/Cards";
import Awards from "../Components/HomePage/Awards";
import CallToAction from "../Components/HomePage/CallToAction";
import SupportingUs from "../Components/HomePage/SupportingUs.js";
import Footer from "../Components/Footer/Footer.js";
import Nav from "../Components/Navigation/Nav";
import "./LandingPage.css";


import { motion } from "framer-motion"

function LandingPage() {
  
/*   const timerRef = useRef(null);
const goToContent = () => {
 
  timerRef.current = setTimeout(() =>  document.getElementById("presentation-section").scrollIntoView(), 10000);
} */
/* PROD : */ 

/* useEffect(() => {
  if (window.location.href === 'http://localhost:3000/'){
    
    goToContent()
  } else {
    document.getElementById("presentation-section").scrollIntoView()
  }

}, []);
  */

/* DEV : useEffect(() => {
  console.log(window.location.href)
  if (window.location.href === 'https://grand-unicorn-28b8d3.netlify.app/'){
    goToContent()
  } else {
    document.getElementById("presentation-section").scrollIntoView()
  }

}, []); */


  return (
    <>
      <Nav />
      <Hero />
      <AboutUs />
      <Cards />
      <Awards />
      <CallToAction />
      <SupportingUs />
      <Footer />
   </>
  );
}

export default LandingPage;


