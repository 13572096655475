import './Blog.css'
import Nav from '../Components/Navigation/Nav';
import BlogArticles from '../Components/Blog/BlogArticles'
import Footer from '../Components/Footer/Footer';
import { motion } from "framer-motion"

const Blog = () => {
    return ( 
    <motion.div transition={{ duration: 1}} initial={{opacity:0}} animate={{opacity:1}} exit={{ opacity: 0 }}>
    <Nav/>
    <div className="blog-content">
        <div className="blog-header">
            <h1>LE BLOG UBETON</h1>
            <h2>Ubéton :</h2>
            <h3>L'entreprise de béton qui préserve l'environnement</h3>
        </div>
        <BlogArticles/>
        </div>
        <Footer/>
        </motion.div> 
    );
}
 
export default Blog;