import { BACKEND_URL } from '../../config'

const BlogArticle = ({article}) => {

    return ( 

        <article>
            <div className='img-container'>
                <img src={`${BACKEND_URL}${article.attributes.image.data.attributes.url}`} alt=''/>
            </div>
            <div className='content-container'>
                <h2 class='article-title'>{article.title}</h2>
                {article.attributes.content.map(paragraph =>(
                    <>
                        <h3 className='section-title'>{paragraph.title}</h3>
                        <p className='article-text'>{paragraph.content}</p>
                    </>
                    ))}
            </div> 
        </article> 
    );
}
 
export default BlogArticle;