import styles from "./legal.module.css";
import Nav from "../Components/Navigation/Nav";
import Footer from "../Components/Footer/Footer";

function Legal() {
  return (
    <>
      <Nav />
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Mentions légales</h1>
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
            pour la confiance en l'économie numérique, il est précisé aux
            utilisateurs du site Ubeton l'identité des différents intervenants
            dans le cadre de sa réalisation et de son suivi.
          </p>
          <div className={styles.paragraph}>
            <h2>Edition du site</h2>
            <p>
              Le présent site, accessible à l'URL www.ubeton.fr (le « Site »),
              est édité par : UBETON SAS société au capital de 1000€, inscrite
              au R.C.S. de Auch sous le numéro 917 773 178 00012, dont le siège
              social est situé au 490 Bis route vieille, 32110 Arblade-le-haut,
              représenté par Olivier Billa dûment habilité.
            </p>
            <p>Le numéro individuel TVA de l'Exploitant est : FR57917773178.</p>
          </div>
          <div className={styles.paragraph}>
            <h2>Hébergement</h2>
            <p>
              Le Site est hébergé par la société OVH, inscrite au R.C.S. de
              Lille sous le numéro B 424 761 419
            </p>
            <p>Adresse : 2, rue Kellermann BP 80157 59053 ROUBAIX Cedex 1</p>
            <p>Email: support@ovh.com ; Tél. : +33 (0)8 203 203 63</p>
          </div>
          <div className={styles.paragraph}>
            <h2>Directeur de la publication</h2>
            <p>
              Le Directeur de la publication du Site est Olivier Billa,
              Président.
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>Nous contacter</h2>
            <p>Par téléphone : 07 668 448 55</p>
            <p>Par email : contact@ubeton.fr</p>
            <p>
              Par courrier : Ubeton, 490 Bis route vieille, 32110
              Arblade-le-haut
            </p>
          </div>
          <div className={styles.paragraph}>
            <h2>Données personnelles</h2>
            <p>
              Le traitement de vos données à caractère personnel est régi par
              notre Charte du respect de la vie privée conformément au Règlement
              Général sur la Protection des Données 2016/679 du 27 avril 2016 («
              RGPD »).
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Legal;
