import Nav from "../Components/Navigation/Nav";
import Footer from "../Components/Footer/Footer";
import './Building.css'

const Building = () => {
    return ( 
    
    <>
    <Nav/>
    <div className="building-section">
        <div className="building-text">
            <h1>Cette partie du site est en construction</h1>
            <p class="signature">- UBéton</p>
        </div>

    </div>
    <Footer/> 
    </>);
}
 
export default Building;